jQuery( document ).ready( function($) {
  
  
    
    
	
	$("#menuIconClose").click(function(){
		$("#menuIconClose").fadeOut();
		$("header").removeClass("opened");
		$("#mainMenu").removeClass("parentMenuExpanded");  
        $('#mainSubMenu').removeClass("collapsed-subMenu");
        $(document).on("click", "#menuIconClose" , function(e) {
         $("#mainMenu li.subMenuHidden").addClass("sel").removeClass("subMenuHidden");
        });
	});
    
    if($('#mainSubMenu').length) {
      $("header").addClass("hasSubmenu");
      $("#mainMenu li.sel").addClass("submenuOpen");
    }
    $(document).on("click", "header.hasSubmenu.opened #mainMenu li.submenuOpen a" , function(e) {
		e.preventDefault();
        $("header.hasSubmenu.opened #mainMenu").addClass("parentMenuExpanded");
        $('#mainSubMenu').addClass("collapsed-subMenu")
        $("header.hasSubmenu.opened #mainMenu li.sel").addClass("subMenuHidden").removeClass("submenuOpen").removeClass("sel");
    });
                              
	$("header #menuIcon").click(function(){
        if($("header").hasClass("openableMenu")){
            $("header").addClass("opened");
            $("#menuIconClose").fadeIn();
              
        }else{  
            if($("header").hasClass("flyOutOpen")){
                //alert("non-flyout menu click");
                $("#secondMenu").fadeIn();  
                $("header").addClass("flyOutOpen");
            }
        }
                
	});
    if($("#page-footer-container").hasClass('darkIntro')){
		$("body").addClass('darkHeader');
    }
    if($("#page-footer-container").hasClass('fsPage')){
		$("body").addClass('fs');
    }
  
    /*
	$("header.flyOutOpen #menuIcon").click(function(){     
            alert("flyout menu click");
            $("#secondMenu").fadeOut();  
            $("header").removeClass("flyOutOpen");        
	});*/

    // Mobile Menu
    $('#toggle-menu').click(function(){
      /*var collapse_content_selector = '#main-menu';
      $(collapse_content_selector).slideToggle(function(){
      });*/
      $('#productMenuContainer').removeClass('closePanel');
      $('#productMenuContainer').css('min-height',$(document).height());
      $("header").addClass("subNavOpened")
    });


    $('#productMenuContainer .closeModal').click(function(){
      $(this).parents('.modalPanel').addClass('closePanel');
      $("header").removeClass("subNavOpened")
    });
    
	$("header .section-link a").click(function(){
		if($("header").hasClass("opened")){
			$("#icon-menu-close").fadeOut();
			$("header").removeClass("opened");		
		}
	});
	
	$(".openReadMore").click(function(){
		$(this).closest(".readMore").find(".readMoreContent").show();
		$(this).hide();
		return false;
	});
    
    function myFunction(x) {
      if (x.matches) { // If media query matches
        $("header").addClass("openableMenu");
      } else {
       $("header").removeClass("openableMenu");
      }
    }
    
    $('.carouselify').slick({        
      responsive: [
         {
	      breakpoint: 5000,
          settings: "unslick"
         }
         ,
         {
          breakpoint: 639,
              settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: true,
              centerMode: true,
              variableWidth: true,
            infinite: true,
            dots: true,
            speed: 400,
            cssEase: 'ease-out'
          }
         }
      ]
    });
    
    $(".revealList .btnShowMore").click(function(){
		$(this).parents(".revealList").eq(0).find(".revealItem").css("display", "flex");
		$(this).hide();
		return false;
	});
    
    $('#searchIcon').click(function(){
		$("#searchPanel").fadeIn();
	});
    
    //open search panel if search keyword in url
    /*if((location.hash.substr("addsearch")) != ""){
        $("#searchPanel").fadeIn();
        alert(location.hash.substr("addsearch"));
    }*/
       
    $('#iconCloseSearch, #addsearch-topcloselink').click(function(){
		$("#searchPanel, #addsearch-results").fadeOut();
	});
    
       
    $('.iconExpander').click(function(){
		if($(this).parents(".expanderBlock").eq(0).hasClass("opened")){
           $(this).parents(".expanderBlock").eq(0).removeClass('opened');
        }else{
           $(this).parents(".expanderBlock").eq(0).addClass('opened');
        }
	});
    
            
    var x = window.matchMedia("(max-width: 3000px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction) // Attach listener function on state changes
  
/** OLDIES **/  
  
  
    $("li.hasSubcategories").hover(
    function() {
      $(this).addClass('openSubMenu');
    }, function() {
      $(this).removeClass('openSubMenu');
    }
  );
 /* 

  $('#welcomeCarousel').slick({ 
      init: prepareHeaderSlide('#welcomeCarousel', 0),            
      dots: true,
      infinite: false,
      speed: 400,
      cssEase: 'ease-in-out',
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 20000,
      responsive: [
         {
          autoplay: true,
          autoplaySpeed: 20000,
          breakpoint: 960,     
          dots: true,
          infinite: false,
          speed: 300,
          cssEase: 'linear',
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
         }
      ]
  });
  
  $('#welcomeCarousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
  	prepareHeaderSlide('#welcomeCarousel', nextSlide);
  });
 */
 /* 
  $('#shopCarousel').slick({
      init: prepareHeaderSlide('#shopCarousel', 0),
      dots: true,
      infinite: false,
      speed: 400,
      cssEase: 'ease-in-out',
      adaptiveHeight: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 20000,
      responsive: [
         {
          autoplay: true,
          autoplaySpeed: 20000,
          breakpoint: 960,     
          dots: true,
          infinite: false,
          speed: 300,
          cssEase: 'linear',
          adaptiveHeight: true,
          slidesToShow: 1,
          slidesToScroll: 1
         }
      ]
  });
  
  
  $('#shopCarousel').on('beforeChange', function(event, slick, currentSlide, nextSlide){
  	prepareHeaderSlide('#shopCarousel', nextSlide);
  });
  

  
  $('#testimonialsCarousel.createCarousel').slick({ 
    init: prepareCarousel('#testimonialsCarousel'),            
    dots: true,
    infinite: false,
    speed: 400,
    cssEase: 'ease-in-out',
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 12000,
    responsive: [
      {
        breakpoint: 960,     
        dots: true,
        infinite: false,
        speed: 300,
        cssEase: 'linear',
        adaptiveHeight: true,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    ]
  });
*/

  
/*

  $('#whatIsCozify .usecases').slick({
      responsive: [
         {
	      breakpoint: 5000,
          settings: "unslick"
         }
         ,
         {
          breakpoint: 960,
          settings: {
      		init: prepareCarousel('#whatIsCozify .usecases'),
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            speed: 400,
            cssEase: 'ease-out',
            adaptiveHeight: false
          }
         }
      ]
  });
  $('#whatIsCozify .usecases').slick('slickFilter','.usecase');
  $('#whatIsCozify .usecases').slick('unslick');
  
  if(($(window).width()) < 960){
    $('#whatIsCozify .usecases').slick({
        responsive: [
           {
            breakpoint: 5000,
            settings: "unslick"
           }
           ,
           {
            breakpoint: 960,
            settings: {
      		  init: prepareCarousel('#whatIsCozify .usecases'),
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: false,
              dots: true,
              speed: 300,
      		  cssEase: 'ease-out',
              adaptiveHeight: false
            }
           }
        ]
    });
  }
  

 
  $('#fromShop .product-grid').slick({             
      dots: true,
      infinite: false,
      speed: 400,
      adaptiveHeight: true,
      slidesToShow: 4,
      slidesToScroll: 4,
      responsive: [
         {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true,
            speed: 400,
            cssEase: 'ease-out',
            adaptiveHeight: true
          }
         }
         ,
         {
           breakpoint: 620,
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1,
             infinite: false,
             dots: true,
            speed: 400,
            cssEase: 'ease-out',
             adaptiveHeight: true
           }
         }
      ]
  });
  $('#fromShop .product-grid').slick('slickFilter','.device');

 

  $('#fromBlog ul').slick({             
      dots: true,
      infinite: false,
      speed: 300,
      adaptiveHeight: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      responsive: [
         {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true,
            speed: 400,
      		cssEase: 'ease-out',
            adaptiveHeight: true
          }
         }
         ,
         {
           breakpoint: 748,
           settings: {
             slidesToShow: 1,
             slidesToScroll: 1,
             infinite: false,
             dots: true,
             speed: 400,
      		 cssEase: 'ease-out',
             adaptiveHeight: true
           }
         }
      ]
  });
  
  

  $('#cozifyNews ul.newsEntries').slick({             
      dots: true,
      infinite: false,
      speed: 400,
      cssEase: 'ease-in-out',
      adaptiveHeight: false,
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: [
         {
          breakpoint: 960,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            dots: true,
            speed: 400,
            cssEase: 'ease-out',
            adaptiveHeight: false
          }
         }
      ]
  });
  
 */ 
  
  // FancyBox
  $(".fancybox-media")
      .attr('rel', 'gallery')
      .fancybox({
          openEffect  : 'fade',
          closeEffect : 'fade',
          nextEffect  : 'elastic',
          prevEffect  : 'elastic',
          padding     : 30,
          wrapCSS   : 'mnml'  
   });
  
   
  
  
  
   $("#gallery a").first().addClass('sel');

   $('#gallery a').click(function(e){
     e.preventDefault();
     var newSizedImageSrc = $(this).attr('href');
     var mainImageDomEl = $('#feature-image')[0]; 
     SimpleTheme.switchImage(newSizedImageSrc, null, mainImageDomEl);

     $("#gallery a").removeClass('sel');
     $(this).addClass('sel');

   });  
  
  $(".bigTabs li").first().addClass('sel');
  var selFirstTab = $(".bigTabs li a").first().attr('href');
  $(".tabContent").hide();
  $(selFirstTab).show();

  $(".bigTabs li a").click(function(){
    $(".tabContent").hide();
    $(".bigTabs li").removeClass('sel');
    $(this).parent('li').addClass('sel');
    //alert($(this).attr('href'));
    $($(this).attr('href')).show();
    return false;
  });
  
    

    // Drawer
    $('.toggle-drawer').click(function(){
      var collapse_content_selector = '#drawer';
      var toggle_switch = $('span.drawer-icon');
      $(collapse_content_selector).slideToggle(function(){
        if($(this).css('display')=='none'){
          toggle_switch.text('+'); //change the button label to be 'Show'
        }else{
          toggle_switch.text('-'); //change the button label to be 'Hide'
        }
      });
    });
	
    
    
    $(".productAccordion li .toggler, .productAccordion li .itemInfo").click(function(){
      if($(this).parents("li").eq(0).hasClass('open')){
        $(this).parents("li").eq(0).removeClass('open');
      }else{
        $(this).parents("li").eq(0).addClass('open');
      }
    });
    
   
  
    
            
    var x = window.matchMedia("(max-width: 3000px)")
    myFunction(x) // Call listener function at run time
    x.addListener(myFunction) // Attach listener function on state changes
    
    if($("#page-footer-container").hasClass('darkIntro')){
		$("body").addClass('darkHeader');
    }
  
  
    

});  